
import { createStore } from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleLayout from "./modules/layout.js"
import moduleUser from './modules/user.js'
import moduleNotification from "./modules/notification.js"
import moduleCoverage from "./modules/coverage.js"
import moduleCategory from "./modules/categories.js"
import moduleDevice from "./modules/device.js"
import modulePlan from "./modules/plan.js"
import moduleBrand from "./modules/brand.js"
import moduleClaim from "./modules/claim.js"
import moduleItem from "./modules/item.js"
import moduleRepair from "./modules/repair.js"
import moduleStore from "./modules/store.js"
import moduleStoreType from "./modules/store-type.js"
import moduleAttribute from "./modules/attributes.js"
import moduleProduct from "./modules/products.js"

const store = createStore({
    state,
    actions,
    getters,
    mutations,
    modules: {
        layout: moduleLayout,
        userList: moduleUser,
        notification: moduleNotification,
        coverage: moduleCoverage,
        category: moduleCategory,
        device: moduleDevice,
        plan: modulePlan,
        brand: moduleBrand,
        item: moduleItem,
        claim: moduleClaim,
        repair: moduleRepair,
        store: moduleStore,
        storeType: moduleStoreType,
        attribute: moduleAttribute,
        product: moduleProduct
    },
    strict: process.env.NODE_ENV !== 'production',
})

export default store
