import env from '../env.js'
import countries from './db/countries.js'
import { getUserData } from '@/helpers/utils'

const state = {
    isLoading: false,
    AppActiveUser: getUserData(),
    domain: env.domain,
    localUserKey: env.localUserKey,
    appName: env.appName,
    config:{
        shortDesc: "Electronic devices insurance company"
    },
    scrollY: 0,
    windowWidth: window.innerWidth,
    countries: countries,
}

export default state
