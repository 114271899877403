import axios from "@/plugins/axios.js"

const actions = {
  fetchDevices({ commit},  payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/devices?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_DEVICES', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchAllDevices({ commit, state }) {
    if(state.list.length) return
    return new Promise((resolve, reject) => {
      axios.get("/devices/all")
        .then((response) => {
          if(response.data.success){
            commit('SET_ALL_DEVICES', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const getters = {

}

const state = {
  list:[],
  pageData:{
    data: []
  }
}

const mutations = {
  SET_ALL_DEVICES(state, list){
    state.list = list
  },
  SET_DEVICES(state, data){
    state.pageData = data
  },
  ADD_DEVICE(state, device){
    state.pageData.data.unshift(device);
  },
  UPDATE_DEVICE(state, device){
    const dataIndex = state.pageData.data.findIndex((p) => p.id == device.id)
      Object.assign(state.pageData.data[dataIndex], device)
  },
  DELETE_DEVICE(state, deviceId){
    const ItemIndex = state.pageData.data.findIndex((item) => item.id == deviceId)
    state.pageData.data.splice(ItemIndex, 1);
  },
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

