import axios from "@/plugins/axios.js"

const actions = {
  fetchCoverages({ commit},  payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/coverages?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_COVERAGES', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchAllCoverages({ commit, state }) {
    if(state.list.length) return
    return new Promise((resolve, reject) => {
      axios.get("/coverages/all")
        .then((response) => {
          if(response.data.success){
            commit('SET_ALL_COVERAGES', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const getters = {

}

const state = {
  list:[],
  pageData:{
    data:[]
  }
}

const mutations = {
  SET_ALL_COVERAGES(state, list){
    state.list = list
  },
  SET_COVERAGES(state, data){
    state.pageData = data
  },
  ADD_COVERAGE(state, coverage){
    state.pageData.data.unshift(coverage);
  },
  UPDATE_COVERAGE(state, coverage){
    const dataIndex = state.pageData.data.findIndex((p) => p.id == coverage.id)
      Object.assign(state.pageData.data[dataIndex], coverage)
  },
  DELETE_COVERAGE(state, itemId){
    const ItemIndex = state.pageData.data.findIndex((item) => item.id == itemId)
    state.pageData.data.splice(ItemIndex, 1);
  },

}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

