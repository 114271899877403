import { getUserStorable } from '@/helpers/utils'

const mutations = {
  TOGGLE_LOADER(state, loader) {
    state.isLoading = loader;
  },
  UPDATE_WINDOW_SCROLL_Y(state) {
    state.scrollY = window.scrollY;
  },
  UPDATE_WINDOW_WIDTH(state) {
    state.windowWidth = window.innerWidth
  },
  LOGOUT_USER(state) {
    localStorage.removeItem(state.localUserKey);
    state.AppActiveUser = {};
  },
  UPDATE_USER_INFO(state, user) {
    if(!user.id){ localStorage.removeItem(state.localUserKey); return};
    state.AppActiveUser 
        ? Object.assign(state.AppActiveUser, user)
        : state.AppActiveUser = {...user};

    const get_localUser = localStorage.getItem(state.localUserKey);
    const storedData = JSON.parse(unescape(atob(get_localUser)));
    storedData.user = getUserStorable(user);
    localStorage.setItem(state.localUserKey, btoa(escape(JSON.stringify(storedData))));
  },
  SET_AUTH_TOKEN(state, data){
    const expirationTime = new Date( new Date().getTime() + +data.expiresIn * 1000);
    const config = {
        token: data.accessToken,
        tokenExpirationDate: expirationTime.toISOString(),
        user: getUserStorable(data.user),
    }
    const encryptedValue = btoa(escape(JSON.stringify(config)));
    localStorage.setItem(state.localUserKey, encryptedValue);
  },
  SET_COUNTRIES(state, countries){
    state.countries = [...countries];
  },
}

export default mutations

