export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '/main',
        redirect: '/',
        component: () => import('@/layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('@views/Dashboard.vue'),
                meta: {
                  title: 'Dashboard',
                  authRequired: true,
                }
            },
            {
              path: '/coverages',
              name: 'coverages',
              component: () => import('@views/catalog/CoverageList.vue'),
              meta: {
                title: "Coverages",
                authRequired: true,
              }
           },
           {
              path: '/devices',
              name: 'devices',
              component: () => import('@views/devices/DeviceList.vue'),
              meta: {
                title: "Devices",
                authRequired: true,
              }
            },
            {
              path: '/devices/:deviceId(\\d+)',
              name: 'view-device',
              component: () => import('@views/devices/DeviceShow.vue'),
              meta: {
                title: "View device",
                authRequired: true,
              },
            },
            {
              path: '/categories',
              name: 'categories',
              component: () => import('@views/catalog/CategoryList.vue'),
              meta: {
                title: "Categories",
                authRequired: true,
              }
            },
            {
              path: '/brands',
              name: 'brands',
              component: () => import('@views/catalog/BrandList.vue'),
              meta: {
                title: "Device brands",
                authRequired: true,
              }
            },
            {
              path: '/repairs',
              name: 'repairs',
              component: () => import('@views/catalog/RepairList.vue'),
              meta: {
                title: "Device repairs",
                authRequired: true,
              }
            },
            {
              path: '/products',
              name: 'products',
              component: () => import('@views/products/ProductList.vue'),
              meta: {
                title: "Products",
                authRequired: true,
              }
            },
            {
                path: '/account',
                name: 'account',
                component: () => import('@views/account/UserEdit.vue'),
                meta: {
                  title: 'Profile',
                  authRequired: true,
                },
            },
            {
                path: '/subscriptions',
                name: 'subscription',
                component: () => import('@views/subscriptions/SubscriptionList.vue'),
                meta: {
                  title: "Subscriptions List",
                  authRequired: true,
                },
            },
            {
              path: '/subscriptions/:subId(\\d+)',
              name: 'view-subscription',
              component: () => import('@views/subscriptions/SubscriptionShow.vue'),
              meta: {
                title: "View subscription",
                authRequired: true,
              },
            },
            {
              path: '/appointments',
              name: 'appointments',
              component: () => import('@views/appointments/AppointmentList.vue'),
              meta: {
                title: 'Appointments',
                authRequired: true,
              },
            },
            {
              path: '/appointments/:appointId(\\d+)',
              name: 'view-appointment',
              component: () => import('@views/appointments/AppointmentShow.vue'),
              meta: {
                title: "View appointment",
                authRequired: true,
              },
            },
            {
                path: '/items',
                name: 'items',
                component: () => import('@views/items/ItemList.vue'),
                meta: {
                  title: "Items",
                  authRequired: true,
                },
            },
            {
              path: '/items/:itemId(\\d+)',
              name: 'viwe-item',
              component: () => import('@views/items/ItemShow.vue'),
              meta: {
                title: "View item",
                authRequired: true,
              },
            },
            {
              path: '/claims',
              name: 'claims',
              component: () => import('@views/claims/ClaimList.vue'),
              meta: {
                title: "Claims",
                authRequired: true,
              },
            },
            {
              path: '/claims/:claimId(\\d+)',
              name: 'view-claims',
              component: () => import('@views/claims/ClaimShow.vue'),
              meta: {
                title: "View claim",
                authRequired: true,
              },
            },
            {
              path: '/leases',
              name: 'leases',
              component: () => import('@views/leases/LeaseList.vue'),
              meta: {
                title: 'Lease Activity',
                authRequired: true,
              },
            },
            {
              path: '/leases/:leaseId(\\d+)',
              name: 'view-lease',
              component: () => import('@views/leases/LeaseShow.vue'),
              meta: {
                title: "View Lease",
                authRequired: true,
              },
            },
            {
              path: '/sell-requests',
              name: 'sell-requests',
              component: () => import('@views/sell-requests/SellRequests.vue'),
              meta: {
                title: 'Sell Requests',
                authRequired: true,
              },
            },
            {
              path: '/sell-requests/:requestId(\\d+)',
              name: 'view-sell-request',
              component: () => import('@views/sell-requests/SellRequestShow.vue'),
              meta: {
                title: "View Sell Request",
                authRequired: true,
              },
            },
            {
              path: '/products',
              name: 'products',
              component: () => import('@views/products/ProductList.vue'),
              meta: {
                title: 'Products',
                authRequired: true,
              },
            },
            {
              path: '/products/:productId(\\d+)',
              name: 'view-product',
              component: () => import('@views/products/ProductShow.vue'),
              meta: {
                title: "View Product Details",
                authRequired: true,
              },
            },
            {
              path: '/orders',
              name: 'orders',
              component: () => import('@views/orders/OrderList.vue'),
              meta: {
                title: 'Orders',
                authRequired: true,
              },
            },
            {
              path: '/orders/:orderId(\\d+)',
              name: 'view-order',
              component: () => import('@views/orders/OrderShow.vue'),
              meta: {
                title: "View Order Details",
                authRequired: true,
              },
            },
            
            {
              path: '/users',
              name: 'users-list',
              component: () => import('@views/users/UserList.vue'),
              meta: {
                title: "Users List",
                authRequired: true,
              },
            },
            {
              path: '/users/create',
              name: 'users-create',
              component: () => import('@views/users/UserCreate.vue'),
              meta: {
                title: "Create User",
                authRequired: true,
              },
            },
            {
              path: '/users/:userId(\\d+)',
              name: 'user-edit',
              component: () => import('@views/users/edit/UserEdit.vue'),
              meta: {
                title: "Vew User",
                authRequired: true,
              },
            },
            {
              path: '/stores',
              name: 'stores',
              component: () => import('@views/stores/StoreList.vue'),
              meta: {
                title: 'Stores',
                authRequired: true,
              },
            },
            {
              path: '/stores/:storeId(\\d+)',
              name: 'view-store',
              component: () => import('@views/stores/StoreShow.vue'),
              meta: {
                title: "View store",
                authRequired: true,
              },
            },
            {
              path: '/store-types',
              name: 'store-types',
              component: () => import('@views/stores/StoreTypeList.vue'),
              meta: {
                title: "Store types",
                authRequired: true,
              }
            },

            {
              path: '/plans',
              name: 'plans',
              component: () => import('@views/catalog/PlanList.vue'),
              meta: {
                title: "Plans",
                authRequired: true,
              }
            },
            {
              path: '/attributes',
              name: 'attributes',
              component: () => import('@views/attributes/AttributeList.vue'),
              meta: {
                title: 'Attributes',
                authRequired: true,
              }
            },
            {
              path: '/notifications',
              name: 'notifications',
              component: () => import('@views/notification/NotificationList.vue'),
              meta: {
                title: "Notifications",
                authRequired: true,
              }
          },
        ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '/full',
        component: () => import('@/layouts/Full.vue'),
        children: [
          {
              path: '/login',
              name: 'login',
              component: () => import('@/views/pages/Login.vue'),
              meta: {
                guestRequired: true,
                title: "Login"
              }
          },
          {
              path: '/error-404',
              name: 'error-404',
              component: () => import('@/views/pages/Error404.vue'),
              meta: {
                title: "404 - Page not Found"
              }
          },
          {
              path: '/error-500',
              name: 'error-500',
              component: () => import('@/views/pages/Error500.vue'),
              meta: {
                title: "500 - Server error"
              }
          },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '/:any(.*)',
        redirect: '/error-404'
    },

]