import axios from "@/plugins/axios.js"

const actions = {
  fetchPlans({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/plans")
        .then((response) => {
          if(response.data.success){
            commit('SET_PLANS', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}

const getters = {

}

const state = {
  list:[]
}

const mutations = {
  SET_PLANS(state, plans){
    state.list = plans
  },
  ADD_PLAN(state, plan){
    state.list.unshift(plan);
  },
  UPDATE_PLAN(state, plan){
    const dataIndex = state.list.findIndex((p) => p.id == plan.id)
      Object.assign(state.list[dataIndex], plan)
  },
  DELETE_PLAN(state, planId){
    const ItemIndex = state.list.findIndex((item) => item.id == planId)
    state.list.splice(ItemIndex, 1);
  },

}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

