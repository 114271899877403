import axios from "@/plugins/axios.js"

const actions = {
  fetchTypes({ commit, state }) {
    if(state.list.length) return
    return new Promise((resolve, reject) => {
      axios.get("/store-types")
        .then((response) => {
          if(response.data.success){
            commit('SET_ITEMS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const getters = {

}

const state = {
  list:[]
}

const mutations = {
  ADD_ITEM(state, item){
    state.list.unshift(item);
  },
  SET_ITEMS(state, list){
    state.list = [...list];
  },
  UPDATE_ITEM(state, item){
    const dataIndex = state.list.findIndex((p) => p.id == item.id)
    Object.assign(state.list[dataIndex], item)
  },
  DELETE_ITEM(state, itemId){
    const ItemIndex = state.list.findIndex((item) => item.id == itemId)
    state.list.splice(ItemIndex, 1);
  },
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

